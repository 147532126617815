<script>
  import DoubleTheDonation from "$lib/components/Embed/DoubleTheDonation.svelte";
  import EmbedWrapper from "$lib/components/Embed/EmbedWrapper.svelte";
  import FacebookEmbed from "$lib/components/Embed/FacebookEmbed.svelte";
  import InstagramEmbed from "$lib/components/Embed/InstagramEmbed.svelte";
  import SoundCloudEmbed from "$lib/components/Embed/SoundCloudEmbed.svelte";
  import SpotifyEmbed from "$lib/components/Embed/SpotifyEmbed.svelte";
  import TwitterEmbed from "$lib/components/Embed/TwitterEmbed.svelte";
  import TypeformEmbed from "$lib/components/Embed/TypeformEmbed.svelte";

  let className = null;
  export { className as class };

  export let data;

  /**
   * TODO: Implement the following unsupported embed types: Google Maps, Juxtapose, and Spotify.
   *
   * Corresponding form factors to be used in the map for future reference:
   *
   * googlemaps -> Google Maps
   * juxtapose -> Juxtapose
   * spotify -> Spotify
   */
  const EMBED_COMPONENT_MAP = {
    "Double the Donation": DoubleTheDonation,
    facebook: FacebookEmbed,
    instagram: InstagramEmbed,
    soundcloud: SoundCloudEmbed,
    spotify: SpotifyEmbed,
    twitter: TwitterEmbed,
    typeform: TypeformEmbed
  };

  const getComponent = (source) => {
    let component = EMBED_COMPONENT_MAP[source];
    if (!component) {
      // eslint-disable-next-line no-console
      console.warn(
        `Source missing in Embed component map: ${source}. Valid sources include: ${Object.keys(
          EMBED_COMPONENT_MAP
        ).join(", ")}`
      );
    }
    return component;
  };

  let component = getComponent(data.fields.source);
</script>

<EmbedWrapper class={className}>
  <svelte:component this={component} {data} />
</EmbedWrapper>
