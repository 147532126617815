<script context="module">
  import Bio from "$lib/contentTypes/Bio.svelte";
  import BlockQuote from "$lib/contentTypes/BlockQuote.svelte";
  import CallToActionComponent from "$lib/contentTypes/CallToActionComponent.svelte";
  import ContentGroup from "$lib/contentTypes/ContentGroup.svelte";
  import Contributors from "$lib/contentTypes/Contributors.svelte";
  import Donate from "$lib/contentTypes/Donate.svelte";
  import EmailSignupForm from "$lib/contentTypes/EmailSignupForm.svelte";
  import Embed from "$lib/contentTypes/Embed.svelte";
  import HTMLSnippet from "$lib/contentTypes/HTMLSnippet.svelte";
  import FundraisingGraph from "$lib/contentTypes/FundraisingGraph.svelte";
  import HeroComponent from "$lib/contentTypes/HeroComponent.svelte";
  import ImageComponent from "$lib/contentTypes/ImageComponent.svelte";
  import ImageGroup from "$lib/contentTypes/ImageGroup.svelte";
  import ItemPreviewList from "$lib/contentTypes/ItemPreviewList.svelte";
  import CenteredLayout from "$lib/contentTypes/CenteredLayout.svelte";
  import LinkComponent from "$lib/contentTypes/LinkComponent.svelte";
  import Menu from "$lib/contentTypes/Menu.svelte";
  import NewsletterList from "$lib/components/Form/NewsletterList.svelte";
  import SectionComponent from "$lib/contentTypes/SectionComponent.svelte";
  import SplitLayoutComponent from "$lib/contentTypes/SplitLayoutComponent.svelte";
  import Video from "$lib/contentTypes/Video.svelte";
  import WordCloud from "$lib/contentTypes/WordCloud.svelte";

  export const COMPONENT_MAP = {
    bio: Bio,
    blockQuote: BlockQuote,
    callToActionComponent: CallToActionComponent,
    contentGroup: ContentGroup,
    contributors: Contributors,
    donateComponent: Donate,
    emailSignup: EmailSignupForm,
    embed: Embed,
    htmlSnippet: HTMLSnippet,
    /** @deprecated */
    fundraiseUpElement: HTMLSnippet,
    fundraisingGraph: FundraisingGraph,
    heroComponent: HeroComponent,
    imageComponent: ImageComponent,
    imageGroup: ImageGroup,
    itemPreviewList: ItemPreviewList,
    layout: CenteredLayout,
    linkComponent: LinkComponent,
    menu: Menu,
    newsletterList: NewsletterList,
    sectionComponent: SectionComponent,
    splitLayout: SplitLayoutComponent,
    video: Video,
    wordCloud: WordCloud
  };
</script>

<script>
  import RichText from "$lib/components/RichText.svelte";

  let clazz = "";
  export { clazz as class };
  export let body;
</script>

<div class="{clazz} mb-15 md:mb-20 lg:mb-25">
  <RichText node={body} componentMap={COMPONENT_MAP} />
</div>
