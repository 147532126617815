import RichText from "$lib/components/RichText.svelte";
import { getYoutubeThumbnailUrl, extractYoutubeVideoID } from "$lib/utilities/components/embeds";

export const mapAccordionItems = (items) =>
  items.map((item) => ({
    headline: item.fields.headline,
    body: RichText,
    bodyProps: {
      node: item.fields.body
    },
    active: item.fields.isOpen
  }));

export const mapListQA = (items) =>
  items.map((item) => ({
    question: item.fields.headline,
    answer: {
      body: RichText,
      bodyProps: {
        node: item.fields.body
      }
    }
  }));

export const mapListItems = (items) => {
  return items.map((item) => {
    if (item.fields.body) {
      return {
        headline: item.fields.headline,
        component: RichText,
        componentProps: {
          node: item.fields.body
        }
      };
    }

    return {
      headline: item.fields.headline
    };
  });
};

export const mapTimeline = (items) => {
  const result = [];
  // Start index at -1 to create no accordions
  let currentAccordionIndex = -1;
  let lastTimeRecorded = null;
  items.forEach((item) => {
    const event = {
      headline: item.fields.headline,
      subtitle: item.fields.subtitle,
      body: RichText,
      bodyProps: {
        node: item.fields.body
      },
      active: item.fields.isOpen
    };
    const currentTime = item.fields.time;
    // Components that have a different time than the previous create an accordion
    if ((currentTime && currentTime !== lastTimeRecorded) || currentAccordionIndex === -1) {
      // Creates new accordion
      currentAccordionIndex++;
      lastTimeRecorded = currentTime;
      result[currentAccordionIndex] = { datetime: currentTime, events: [] };
    }
    // Push event to current accordion
    result[currentAccordionIndex].events.push(event);
  });

  return result;
};

export const mapVideoPlaylistItems = (data) => {
  return data.map((item) => {
    const defaultThumbnail = getYoutubeThumbnailUrl(item.fields?.url);

    return {
      header: item.fields.title,
      body: item.fields.description,
      videoCoverImage: defaultThumbnail
        ? {
            src: defaultThumbnail,
            alt: ""
          }
        : undefined,
      videoId: extractYoutubeVideoID(item.fields?.url)
    };
  });
};
